import config from 'config'
// import { Compare, Checkout, MyAccount, Static, CustomCmsPage, CmsData } from './asyncRoutes'
// import { CustomCmsPage, CmsData } from './asyncRoutes'
import { CustomCmsPage, CmsData, MyAccount, Auth } from './asyncRoutes'
import specialEvents from './specialEvents'

const Home = () => import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Home.vue')
const PageNotFound = () => import(/* webpackChunkName: "vsf-not-found" */ 'theme/pages/PageNotFound.vue')
const ServerError = () => import(/* webpackChunkName: "vsf-server-error" */ 'theme/pages/ServerError.vue')
const Product = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product.vue')
const Category = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category.vue')
const VipCategory = () => import(/* webpackChunkName: "vsf-vip-category" */ 'theme/pages/VipCategory.vue')
const Presents = () => import(/* webpackChunkName: "vsf-presents" */ 'theme/pages/Presents.vue')
const CmsPage = () => import(/* webpackChunkName: "vsf-cms-page" */ 'theme/pages/CmsPage.vue')
const AboutUs = () => import(/* webpackChunkName: "vsf-about-us" */ 'theme/pages/AboutUs.vue')
const CorporativeClients = () => import(/* webpackChunkName: "vsf-corporative-clients" */ 'theme/pages/CorporativeClients.vue')
// const Checkout = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout.vue')
// const Cart = () => import(/* webpackChunkName: "vsf-cart" */ 'theme/pages/Cart.vue')
const Offices = () => import(/* webpackChunkName: "vsf-offices" */ 'theme/pages/OurOffices.vue')
const SocialResponsiblity = () => import(/* webpackChunkName: "vsf-social-responsiblity" */ 'theme/pages/SocialResponsiblity.vue')
const Structure = () => import(/* webpackChunkName: "vsf-structure" */ 'theme/pages/Structure.vue')
const Career = () => import(/* webpackChunkName: "vsf-career" */ 'theme/pages/Career.vue')
// const BarJimBeam = () => import(/* webpackChunkName: "vsf-bar-jimbeam" */ 'theme/pages/BarJimBeam.vue')
// const JimBeamHighball = () => import(/* webpackChunkName: "vsf-jimneam-highball" */ 'theme/pages/StaticEvents/JimBeamHighball.vue')
const OurBrands = () => import(/* webpackChunkName: "vsf-our-brands" */ 'theme/pages/ourBrands.vue')
const HighlandPark = () => import(/* webpackChunkName: "vsf-highland-park" */ 'theme/pages/brands/HighlandPark.vue')
// const Courvoisier = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/brands/Courvoisier.vue')
// const Auchentoshan = () => import(/* webpackChunkName: "vsf-auchentoshan" */ 'theme/pages/brands/Auchentoshan.vue')
// const JimBeam = () => import(/* webpackChunkName: "vsf-jimbeam" */ 'theme/pages/brands/JimBeam.vue')
const Macallan = () => import(/* webpackChunkName: "vsf-macallan" */ 'theme/pages/brands/Macallan.vue')
// const RokuGin = () => import(/* webpackChunkName: "vsf-roku-jin" */ 'theme/pages/brands/RokuGin.vue')
const newRokuGin = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/newRokuGin.vue')
const CollectionPage = () => import(/* webpackChunkName: "vsf-collection-page" */ 'theme/pages/brands/Collections/CollectionPage.vue')
// const MakersMark = () => import(/* webpackChunkName: "vsf-makers-mark" */ 'theme/pages/brands/MakersMark.vue')
const DoubleOak = () => import(/* webpackChunkName: "vsf-double-oak" */ 'theme/pages/brands/DoubleOak.vue')
// const TheFamousGrouse = () => import(/* webpackChunkName: "vsf-famous-grouse" */ 'theme/pages/brands/TheFamousGrouse.vue')
const MaintenanceWork = () => import(/* webpackChunkName: "vsf-maintenance-work" */ 'theme/pages/MaintenanceWork.vue')
const NewsPost = () => import(/* webpackChunkName: "vsf-news-post" */ 'theme/pages/NewsPost.vue')
const News = () => import(/* webpackChunkName: "vsf-news" */ 'theme/pages/News.vue')
const JimBeamTusa = () => import(/* webpackChunkName: "vsf-jimbeam-tusa" */ 'theme/pages/StaticEvents/JimBeamTusa.vue')
const HighlandParkIndian = () => import(/* webpackChunkName: "vsf-highland-indian" */ 'theme/pages/StaticEvents/HighlandParkIndian.vue')
const Macallan72YO = () => import(/* webpackChunkName: "vsf-macallan72yo" */ 'theme/pages/StaticEvents/Macallan72YO.vue')
const AuchentoshanSummer = () => import(/* webpackChunkName: "vsf-auchentoshan-summer" */ 'theme/pages/StaticEvents/AuchentoshanSummer.vue')
// const CollectionReleases = () => import(/* webpackChunkName: "vsf-collection-releases" */ 'theme/pages/CollectionReleases.vue')
const MapCollections = () => import(/* webpackChunkName: "vsf-map-collections" */ 'theme/pages/brands/Collections/MapCollections.vue')
const AuchentoshanPromo1 = () => import(/* webpackChunkName: "vsf-auchentoshan-promo-1" */ 'theme/pages/StaticEvents/AuchentoshanPromo1.vue')
const AuchentoshanPromo2 = () => import(/* webpackChunkName: "vsf-auchentoshan-promo-2" */ 'theme/pages/StaticEvents/AuchentoshanPromo2.vue')
const HPLimitedReleasesCollection = () => import(/* webpackChunkName: "vsf-hp-limited-releases-collection" */ 'theme/pages/brands/Collections/HPLimitedReleasesCollection.vue')
const JimBeam2020 = () => import(/* webpackChunkName: "vsf-jim-beam-2020" */ 'theme/pages/StaticEvents/JimBeam2020.vue')
const JBPerekrestok2020 = () => import(/* webpackChunkName: "vsf-jim-beam-perekrestok-2020" */ 'theme/pages/StaticEvents/JBPerekrestok2020.vue')
// const MetroExpo = () => import(/* webpackChunkName: "vsf-metro-expo" */ 'theme/pages/StaticEvents/MetroExpo.vue')
// const BartelloAfterMetroExpo = () => import(/* webpackChunkName: "vsf-bartello" */ 'theme/pages/StaticEvents/BartelloAfterMetroExpo.vue')
const TcFamous = () => import(/* webpackChunkName: "vsf-tc-famous" */ 'theme/pages/brands/TcFamous.vue')
// const Hpontr = () => import(/* webpackChunkName: "vsf-hpontr" */ 'theme/pages/brands/Hpontr.vue')
const Rokuontr = () => import(/* webpackChunkName: "vsf-rokuontr" */ 'theme/pages/brands/Rokuontr.vue')
// const Tfgontr = () => import(/* webpackChunkName: "vsf-tfgontr" */ 'theme/pages/brands/Tfgontr.vue')
// const Mmontr = () => import(/* webpackChunkName: "vsf-mmontr" */ 'theme/pages/brands/Mmontr.vue')
const CourvoisierVueDeParis = () => import(/* webpackChunkName: "vsf-courvoisier-vue-de-paris" */ 'theme/pages/StaticEvents/CourvoisierVueDeParis.vue')
const Alwayswelcome = () => import(/* webpackChunkName: "vsf-alwayswelcome" */ 'theme/pages/StaticEvents/Alwayswelcome.vue')
const WishlistPage = () => import(/* webpackChunkName: "vsf-wishlistpage" */ 'theme/pages/WishlistPage.vue')
const Sitemap = () => import(/* webpackChunkName: "vsf-sitemap" */ 'theme/pages/Sitemap.vue')
const Hpmgnt = () => import(/* webpackChunkName: "vsf-about-us" */ 'theme/pages/Hpmgnt.vue')
const Anightonearth = () => import(/* webpackChunkName: "vsf-about-us" */ 'theme/pages/Anightonearth.vue')
const CafeCourvoisier = () => import(/* webpackChunkName: "vsf-about-us" */ 'theme/pages/CafeCourvoisier.vue')
const Courvoisier2022 = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/brands/Courvoisier2022.vue')
const BrandZone = () => import(/* webpackChunkName: "vsf-about-us" */ 'theme/pages/BrandZone.vue')
const macallanDownToWork = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/macallanDownToWork.vue')
const macallanHarmony = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/macallanHarmony.vue')
const macallanSpbTier = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/macallanSpbTier.vue')
const macallanSherryOak25 = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/macallanSherryOak25.vue')
const macallanSherryOak30 = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/macallanSherryOak30.vue')
const highlandPark25 = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/highlandPark25.vue')
const blackRam = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/blackRam.vue')
const FeedbackPreferences = () => import(/* webpackChunkName: "vsf-about-us" */ 'theme/pages/FeedbackPreferences.vue')
const NewYear2023 = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/NewYear2023.vue')
const LimitirovannieRelizi = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/LimitirovannieRelizi.vue')
const BuyLimitedReleases = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/BuyLimitedReleases.vue')
const SetTropez = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/SetTropez.vue')
const schmidtSupreme = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/schmidtSupreme.vue')
const NewPortfolioDecanter = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/NewPortfolioDecanter.vue')
const NewPortfolioWinestyle = () => import(/* webpackChunkName: "vsf-courvoisier" */ 'theme/pages/NewPortfolioWinestyle.vue')
const naud = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/naud.vue')
const heriose = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/heriose.vue')
const martignac = () => import(/* webpackChunkName: "vsf-new-roku-jin" */ 'theme/pages/brands/Rich/Pages/martignac.vue')
const Quality = () => import(/* webpackChunkName: "vsf-quality" */ 'theme/pages/Quality.vue')
const CatalogSectionsList = () => import(/* webpackChunkName: "vsf-category-sections-list" */ 'theme/pages/CatalogSectionsList.vue')

let routes = [
  { name: 'home', path: '/', component: Home, alias: '/pwa.html', meta: {title: 'Официальный сайт', description: 'Maxxium Russia'} },
  { name: 'page-not-found', path: '/page-not-found', component: PageNotFound },
  { name: 'error', path: '/error', component: ServerError, meta: { layout: 'minimal' } },
  { name: 'custom-cms-page', path: '/custom-cms-page', component: CustomCmsPage },
  { name: 'cms-page-sync', path: '/cms-page-sync', component: CmsData, props: {identifier: 'about-us', type: 'Page', sync: true} },
  { name: 'cms-page', path: '/page/:slug', component: CmsPage },
  { name: 'about-us-career', path: '/about-us/career', component: Career, props: { slug: 'career' } },
  { name: 'about-us-structure', path: '/about-us/structure', component: Structure },
  { name: 'about-us-section', path: '/about-us/:slug', component: CmsPage },
  { name: 'about-us', path: '/about-us', component: AboutUs, meta: {title: 'О компании', description: 'О компании Maxxium'} },
  // { name: 'cart', path: '/cart', component: Cart },
  { name: 'our-offices', path: '/our-offices', component: Offices },
  { name: 'corporative-clients', path: '/corporative-clients', component: CorporativeClients },
  { name: 'social-responsiblity', path: '/about-us/social/:slug', component: SocialResponsiblity },
  // { name: 'bar-jim-beam', path: '/jimbeambar', component: BarJimBeam, props: { slug: 'bar-jim-beam' } },
  { name: 'jim-beam-tusa', path: '/jimbeamtusa', component: JimBeamTusa },
  // { name: 'jim-beam-highball', path: '/jimbeamhighball', component: JimBeamHighball, props: { slug: 'jim-beam-highball' } },
  { name: 'highland-park-indian', path: '/highlandpark', component: HighlandParkIndian },
  { name: 'macallan72YO', path: '/macallan72YO', component: Macallan72YO },
  { name: 'auchentoshan-summer', path: '/auchentoshansummer', component: AuchentoshanSummer },
  { name: 'my-account', path: '/my-account', component: MyAccount, props: {title: 'Моя информация'} },
  { name: 'our-brands', path: '/brands', component: OurBrands },
  { name: 'auchentoshan-promo-1', path: '/auchentoshan-promo', component: AuchentoshanPromo1 },
  { name: 'auchentoshan-promo-2', path: '/promo', component: AuchentoshanPromo2 },
  { name: 'jim-beam-2020', path: '/jimbeam2020', component: JimBeam2020 },
  { name: 'jim-beam-perekrestok-2020', path: '/jb_perekrestok2020', component: JBPerekrestok2020 },
  { name: 'alwayswelcome', path: '/alwayswelcome', component: Alwayswelcome, meta: { title: 'Maxxium Russia' } },
  { name: 'wishlistpage', path: '/wishlist', component: WishlistPage },
  { name: 'sitemap', path: '/sitemap', component: Sitemap },
  { name: 'hpmgnt', path: '/hpmgnt', component: Hpmgnt, meta: {title: 'Highland Park', description: 'Highland Park'} },
  { name: 'anightonearth', path: '/anightonearth', component: Anightonearth, meta: {title: 'Ночь на земле в Шотландии', description: 'Ночь на земле в Шотландии'} },
  { name: 'cafe-courvoisier', path: '/cafe-courvoisier', component: CafeCourvoisier, meta: {title: 'Рецепт кофе Courvoisier Espresso от магазина элитного алкоголя Maxxium', description: 'Попробуйте деликатную палитру вкуса французского коньяка Courvoisier и свежезаваренного кофе. Прекрасное сочетание вкуса в коктейлях Courvoisier Expresso в рецепте на нашем сайте.'} },
  { name: 'courvoisier2022', path: '/courvoisier2022', component: Courvoisier2022, meta: {title: 'Новый дизайн упаковки Courvoisier - воссоздание оригинального образа', description: 'Подробности изменения оформления упаковки коньяка Courvoisier в 2022 году. Модернизация основных элементов оформления, подробности от официального магазина элитного алкоголя Maxxium Russia.'} },
  { name: 'collection', path: '/collection:filters(.*)?', component: BrandZone, meta: {title: 'Коллекционные релизы', description: 'О коллекционных релизах'}, props: { slug: 'ves-katalog' } },
  { name: 'feedback-preferences', path: '/feedback-preferences', component: FeedbackPreferences, meta: {title: 'Опросник', description: 'Опросник'} },
  { name: 'new-year-2023', path: '/new-year-2023', component: NewYear2023, meta: {title: '', description: ''} },
  { name: 'limitirovannie-relizi', path: '/limitirovannie-relizi', component: LimitirovannieRelizi, meta: {title: '', description: ''} },
  { name: 'buylimitedreleases', path: '/buylimitedreleases', component: BuyLimitedReleases, meta: {title: '', description: ''} },
  { name: 'set-tropez', path: '/set-tropez', component: SetTropez, meta: {title: '', description: ''} },
  { name: 'new-portfolio-decanter', path: '/new-portfolio-decanter', component: NewPortfolioDecanter, meta: {title: '', description: ''} },
  { name: 'new-portfolio-winestyle', path: '/new-portfolio-winestyle', component: NewPortfolioWinestyle, meta: {title: '', description: ''} },
  { name: 'new-portfolio-to-buy', path: '/new-portfolio-to-buy', component: NewPortfolioWinestyle, meta: {title: '', description: ''} },
  // BRANDS Start

  // Highland Park
  {
    name: 'highland-park-main-line',
    path: '/highland-park/main-line',
    component: CollectionPage,
    props: { slug: 'main-line', currentCollection: 'main_line', collectionName: 'Основная линейка', activeBreadcrumb: 'Основная линейка', currentBreadcrumbName: 'highlandPark', brandKey: 'highlandPark' }
  },
  {
    name: 'highland-park-high-grades',
    path: '/highland-park/high-grades',
    component: CollectionPage,
    props: { slug: 'high-grades', currentCollection: 'high_grades', collectionName: 'Высокие грейды', activeBreadcrumb: 'Высокие грейды', currentBreadcrumbName: 'highlandPark', brandKey: 'highlandPark' }
  },
  {
    name: 'highland-park-limited-releases',
    path: '/highland-park/limited-releases',
    component: HPLimitedReleasesCollection,
    props: { currentCollection: 'limited_releases', currentBreadcrumbName: 'highlandPark', activeBreadcrumb: 'Лимитированные коллекции', brandKey: 'highlandPark' }
  },
  { name: 'highland-park', path: '/highland-park/:slug', component: HighlandPark },
  { path: `/highland-park/${config.brands.highlandPark.slug}`, alias: '/highland-park' },

  // { name: 'courvoisier', path: '/courvoisier/:slug', component: Courvoisier },
  // { path: `/courvoisier/${config.brands.courvoisier.slug}`, alias: '/courvoisier' },

  // { name: 'auchentoshan', path: '/auchentoshan/:slug', component: Auchentoshan },
  // { path: `/auchentoshan/${config.brands.auchentoshan.slug}`, alias: '/auchentoshan' },

  // { name: 'jim-beam', path: '/jim-beam/:slug', component: JimBeam },
  // { path: `/jim-beam/${config.brands.jimBeam.slug}`, alias: '/jim-beam' },

  // Macallan
  {
    name: 'sherry-oak',
    path: '/macallan/sherry-oak',
    component: CollectionPage,
    props: { slug: 'sherry-oak', currentCollection: 'sherry_oak', sortForProductCollection: 'bq_4749.keyword:asc', collectionName: 'Sherry oak', currentBreadcrumbName: 'macallan', activeBreadcrumb: 'Sherry Oak', brandKey: 'macallan' }
  },
  {
    name: 'limited-releases',
    path: '/macallan/limited-releases',
    component: MapCollections,
    props: { currentCollection: 'limited_releases', currentBreadcrumbName: 'macallan', activeBreadcrumb: 'Limited releases', cmsIndentifier: 'limited-releases', collectionSliderIndentifier: 'limited-releases', brandKey: 'macallan' }
  },
  {
    name: 'the-edition-series',
    path: '/macallan/limited-releases/the-edition-series',
    component: CollectionPage,
    props: { slug: 'the-edition-series', currentCollection: 'the_edition_series', sortForProductCollection: 'id:desc', collectionName: 'The edition series', currentBreadcrumbName: 'macallanLimitedReleases', activeBreadcrumb: 'The Edition Series', brandKey: 'macallan' }
  },
  {
    name: 'the-macallan-red-collection',
    path: '/macallan/limited-releases/the-macallan-red-collection',
    component: CollectionPage,
    props: { slug: 'the-macallan-red-collection', currentCollection: 'the_macallan_red_collection', sliderCenterIdentifier: 'the-macallan-red-collection-slider', sortForProductCollection: 'sku:asc', collectionName: 'The Macallan Red Collection', currentBreadcrumbName: 'macallanLimitedReleases', activeBreadcrumb: 'The Macallan Red Collection', brandKey: 'macallan' }
  },
  {
    name: 'travel-retail-exclusives',
    path: '/macallan/travel-retail-exclusives',
    component: MapCollections,
    props: { currentCollection: 'retail_exclusives', currentBreadcrumbName: 'macallan', activeBreadcrumb: 'Travel retail exclusives', cmsIndentifier: 'travel-retail-exclusives', collectionSliderIndentifier: 'travel-retail-exclusives', brandKey: 'macallan' }
  },
  {
    name: 'past-releases',
    path: '/macallan/past-releases',
    component: MapCollections,
    props: { currentCollection: 'past_releases', currentBreadcrumbName: 'macallan', activeBreadcrumb: 'Past releases', cmsIndentifier: 'past-releases', collectionSliderIndentifier: 'past-releases', brandKey: 'macallan' }
  },
  {
    name: 'magnum',
    path: '/macallan/limited-releases/magnum',
    component: CollectionPage,
    props: { slug: 'collection-magnum', collectionName: '2672', activeMainBlock: 'MagnumMainBlock', sliderCenterIdentifier: 'limited-releases-magnum', currentCollection: 'magnum', currentBreadcrumbName: 'macallan', activeBreadcrumb: 'Magnum', brandKey: 'macallan' }
  },
  {
    name: 'triple-cask',
    path: '/macallan/triple-cask',
    component: CollectionPage,
    props: { slug: 'triple-cask-matured', currentCollection: 'triple_cask_matured', collectionName: 'Triple cask', activeBreadcrumb: 'Triple cask matured', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'rare-cask-batch',
    path: '/macallan/rare-cask-batch',
    component: CollectionPage,
    props: { slug: 'rare-cask', currentCollection: 'rare_cask', collectionName: 'Rare cask Batch', activeBreadcrumb: 'Rare cask batch', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'the-masters-decanter-series',
    path: '/macallan/the-masters-decanter-series',
    component: CollectionPage,
    props: { slug: 'the-masters-decanter-series', currentCollection: 'the_masters_decanter_series', collectionName: 'The Masters Decanter series', activeBreadcrumb: 'The Masters decanter series', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'fine-and-rare',
    path: '/macallan/fine-and-rare',
    component: CollectionPage,
    props: { slug: 'fine-and-rare', currentCollection: 'fine_and_rare', categoryId: '142', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'the-archival-series',
    path: '/macallan/the-archival-series',
    component: CollectionPage,
    props: { slug: 'the-archival-series', currentCollection: 'the_archival_series', categoryId: '143', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'the-edition-series',
    path: '/macallan/the-edition-series',
    component: CollectionPage,
    props: { slug: 'the-edition-series', currentCollection: 'the_edition_series', categoryId: '145', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'double-cask',
    path: '/new-macallan-double-cask',
    component: CollectionPage,
    props: { slug: 'double-cask', collectionName: 'no-collection', activeMainBlock: 'DoubleCaskMainBlock', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'double-cask',
    path: '/macallan/double-cask',
    component: CollectionPage,
    props: { slug: 'double-cask', currentCollection: 'double_cask', collectionName: 'Double cask', activeMainBlock: 'OldDoubleCaskMainBlock', activeBreadcrumb: 'Double Cask', currentBreadcrumbName: 'macallan', brandKey: 'macallan' }
  },
  {
    name: 'macallan-50-years',
    path: '/macallan/limited-releases/the-macallan-50-years-old',
    component: CollectionPage,
    props: { slug: 'collection-macallan-50-years-old', categoryId: '149', activeMainBlock: 'YearsMainBlock', sliderCenterIdentifier: 'limited-releases-50-year', currentBreadcrumbName: 'macallanLimitedReleases', brandKey: 'macallan' }
  },
  {
    name: 'gran-reserva',
    path: '/macallan/limited-releases/gran-reserva',
    component: CollectionPage,
    props: { slug: 'collection-gran-reserva', categoryId: '152', activeMainBlock: 'GranReservaMainBlock', currentBreadcrumbName: 'macallanLimitedReleases', brandKey: 'macallan' }
  },
  {
    name: 'genesis-decanter',
    path: '/macallan/limited-releases/genesis-decanter',
    component: CollectionPage,
    props: { slug: 'collection-genesis-decanter', collectionName: '2672', activeMainBlock: 'GenesisDecanterMainBlock', sliderCenterIdentifier: 'limited-releases-genesis-decanter', currentBreadcrumbName: 'macallanLimitedReleases', brandKey: 'macallan' }
  },
  {
    name: 'exceptional-single-cask',
    path: '/macallan/limited-releases/exceptional-single-cask',
    component: CollectionPage,
    props: { slug: 'collection-exceptional-single-cask', currentCollection: 'main-block-exceptional-single-cask', collectionName: 'Exceptional single cask', currentBreadcrumbName: 'macallanLimitedReleases', activeBreadcrumb: 'Exceptional single cask', brandKey: 'macallan' }
  },
  {
    name: 'classic-cut',
    path: '/macallan/limited-releases/classic-cut',
    component: CollectionPage,
    props: { slug: 'collection-classic-cut', collectionName: '2672', currentCollection: 'classic_cut', sliderCenterIdentifier: 'limited-releases-classic-cut', activeBreadcrumb: 'The Macallan Classic cut – 2017 Release', currentBreadcrumbName: 'macallanLimitedReleases', brandKey: 'macallan' }
  },
  {
    name: 'the-six-pillars-collection',
    path: '/macallan/limited-releases/the-six-pillars-collection',
    component: CollectionPage,
    props: { slug: 'the-six-pillars-collection', currentCollection: 'the_six_pillars_collection', collectionName: '2672', activeBreadcrumb: 'The Macallan The six pillars collection', currentBreadcrumbName: 'macallanLimitedReleases', brandKey: 'macallan' }
  },
  {
    name: 'easter-elchies-black',
    path: '/macallan/limited-releases/easter-elchies-black',
    component: CollectionPage,
    props: { slug: 'easter-elchies-black', currentCollection: 'easter_elchies_black', collectionName: '2672', activeBreadcrumb: 'The Macallan Easter Elchies/Estate', currentBreadcrumbName: 'macallanLimitedReleases', brandKey: 'macallan' }
  },
  {
    name: 'rare-cask-black',
    path: '/macallan/travel-retail-exclusives/rare-cask-black',
    component: CollectionPage,
    props: { slug: 'collection-rare-cask-black', collectionName: '2672', activeMainBlock: 'RareCaskBlackMainBlock', currentBreadcrumbName: 'macallanTravelRetailExclusives', brandKey: 'macallan' }
  },
  {
    name: 'the-quest-collection',
    path: '/macallan/travel-retail-exclusives/the-quest-collection',
    component: CollectionPage,
    props: { slug: 'the-quest-collection', currentCollection: 'the_quest_collection', collectionName: '2672', singleBanner: 'macallan-quest-collection', activeBreadcrumb: 'The Quest collection', currentBreadcrumbName: 'macallanTravelRetailExclusives', brandKey: 'macallan' }
  },
  {
    name: 'the-golden-age-of-travel-series',
    path: '/macallan/travel-retail-exclusives/the-golden-age-of-travel-series',
    component: CollectionPage,
    props: { slug: 'the-golden-age-of-travel-series', currentCollection: 'the_golden_age_of_travel_series', collectionName: '2672', activeBreadcrumb: 'The golden age of travel series', currentBreadcrumbName: 'macallanTravelRetailExclusives', brandKey: 'macallan' }
  },
  {
    name: 'oscuro',
    path: '/macallan/travel-retail-exclusives/oscuro',
    component: CollectionPage,
    props: { slug: 'collection-oscuro', collectionName: '2672', activeMainBlock: 'OscuroMainBlock', currentBreadcrumbName: 'macallanTravelRetailExclusives', brandKey: 'macallan' }
  },
  {
    name: 'fine-oak',
    path: '/macallan/past-releases/fine-oak',
    component: CollectionPage,
    props: { slug: 'fine-oak-collection', currentCollection: 'fine_oak', collectionName: '2672', activeBreadcrumb: 'The Macallan Fine Oak', currentBreadcrumbName: 'macallanPastReleases', brandKey: 'macallan' }
  },
  {
    name: '1824-series',
    path: '/macallan/past-releases/1824-series',
    component: CollectionPage,
    props: { slug: '1824-series-collection', currentCollection: '1824_series', collectionName: '2672', activeBreadcrumb: 'The 1824 series', currentBreadcrumbName: 'macallanPastReleases', brandKey: 'macallan' }
  },
  {
    name: '1824-collection',
    path: '/macallan/past-releases/1824-collection',
    component: CollectionPage,
    props: { slug: '1824-collection', currentCollection: '1824_collection', collectionName: '2672', activeBreadcrumb: 'The 1824 collection', currentBreadcrumbName: 'macallanPastReleases', brandKey: 'macallan' }
  },
  { name: 'macallan', path: '/macallan/:slug', component: Macallan },
  { path: `/macallan/${config.brands.macallan.slug}`, alias: '/macallan' },
  { name: 'roku-gin', path: '/rokugin', component: newRokuGin },
  { name: 'macallan-down-to-work', path: '/macallan-down-to-work', component: macallanDownToWork },
  { name: 'macallan-harmony-rich-cacao', path: '/macallan-harmony-rich-cacao', component: macallanHarmony },
  { name: 'the-macallan-spb-tier-b', path: '/the-macallan-spb-tier-b', component: macallanSpbTier },
  { name: 'macallan-sherry-oak-25', path: '/macallan-sherry-oak-25', component: macallanSherryOak25 },
  { name: 'macallan-sherry-oak-30', path: '/macallan-sherry-oak-30', component: macallanSherryOak30 },
  { name: 'highland-park-25', path: '/highland-park-25', component: highlandPark25 },
  { name: 'blackram', path: '/blackram', component: blackRam },
  { name: 'schmidt-supreme', path: '/schmidt-supreme', component: schmidtSupreme },
  { name: 'naud', path: '/naud', component: naud },
  { name: 'heriose', path: '/heriose', component: heriose },
  { name: 'martignac', path: '/martignac', component: martignac },

  { name: 'tc-famous', path: '/tcfamous', component: TcFamous, meta: { layout: 'Empty', title: 'Maxxium Russia' } },
  // { name: 'hpontr', path: '/hpontr', component: Hpontr, meta: { layout: 'Empty', title: 'Maxxium Russia' } },
  { name: 'rokuontr', path: '/rokuontr', component: Rokuontr, meta: { layout: 'Empty', title: 'Maxxium Russia' } },
  // { name: 'tfgontr', path: '/tfgontr', component: Tfgontr, meta: { layout: 'Empty', title: 'Maxxium Russia' } },
  // { name: 'mmontr', path: '/mmontr', component: Mmontr, meta: { layout: 'Empty', title: 'Maxxium Russia' } },
  { name: 'courvoisier-vue-de-paris', path: '/courvoisier-vue-de-paris', component: CourvoisierVueDeParis, meta: { layout: 'Empty', title: 'Maxxium Russia' } },

  // { name: 'old-roku-gin', path: '/oldrokugin', component: RokuGin },
  // { name: 'makers-mark', path: '/makers-mark/:slug', component: MakersMark },
  // { path: `/makers-mark/${config.brands.makersMark.slug}`, alias: '/makers-mark' },

  // { name: 'the-famous-grouse', path: '/the-famous-grouse/:slug', component: TheFamousGrouse },
  // { path: `/the-famous-grouse/${config.brands.famousGrose.slug}`, alias: '/the-famous-grouse' },

  // BRANDS End
  { name: 'double-oak', path: '/double-oak', component: DoubleOak, props: { slug: 'double-oak' } },
  { name: 'news', path: '/news', component: News },
  { name: 'news-year', path: '/news/:year(\\d{4})', component: News },
  { name: 'confirm-account', path: '/customer/account/confirm', component: Auth, props: {activeBlock: 'Login'} },
  { name: 'log-in', path: '/log-in', component: Auth, props: {activeBlock: 'Login'} },
  { name: 'forgot-pass', path: '/forgot-pass', component: Auth, props: {activeBlock: 'ForgotPass'} },
  { name: 'create-pass', path: '/auth/create-pass', component: Auth, props: {activeBlock: 'PasswordRecovery'} },
  { name: 'news-post', path: '/news/:urlKey', component: NewsPost },
  // { name: 'collection-releases', path: '/c/collection-releases/:filters(.*)?', props: { slug: 'prestige' }, component: CollectionReleases, alias: '/courvoisier-collection' },
  { name: 'presents', path: '/c/presents/:filters(.*)?', props: { slug: 'gifts' }, component: Presents },
  // { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  // { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  // { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  // { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'product', path: '/product/:urlKey', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'collection-product', path: '/product-collection/:collection/:urlKey', component: Product },
  { name: 'category', path: '/c/:slug/:filters(.*)?', component: Category },
  { name: 'vip-category', path: '/special-offer/:hash', component: VipCategory },
  { name: 'vip-product', path: '/p-vip/:urlKey', component: Product },
  { name: 'quality', path: '/quality', component: Quality },
  { name: 'CatalogSectionsList', path: '/catalog-sections-list', component: CatalogSectionsList },
  // { name: 'metro-expo', path: '/metro', component: MetroExpo, meta: { title: 'Metro Expo' } },
  // { name: 'bartello', path: '/bartello', component: BartelloAfterMetroExpo, meta: { title: 'Bartello' } },
  { name: 'sign-up', path: '/sign-up', component: Auth, props: {activeBlock: 'SignUp'} },
  // { name: 'checkout', path: '/checkout', component: Checkout },
  { name: 'my-orders', path: '/my-account/orders', component: MyAccount, props: {activeBlock: 'MyOrders', title: 'Мои заказы'} },
  { name: 'my-order', path: '/my-account/orders/:orderId', component: MyAccount, props: {activeBlock: 'MyOrder'} },
  { name: 'maintenance-work', path: '/maintenance-work', component: MaintenanceWork, meta: { layout: 'event' } },
  { name: 'special-product', path: '/:eventCode/product/:urlKey', component: Product, meta: { layout: 'event' } }, // размещать в самом конце
  ...specialEvents, // размещать в самом конце
  // { name: 'legal', path: '/legal', component: Static, props: {page: 'lorem', title: 'Legal Notice'}, meta: {title: 'Legal Notice', description: 'Legal Notice - example of description usage'} },
  // { name: 'magazine', path: '/magazine', component: Static, props: {page: 'lorem', title: 'Magazine'} },
  // { name: 'sale', path: '/sale', component: Static, props: {page: 'lorem', title: 'Sale'} },
  // { name: 'order-tracking', path: '/order-tracking', component: Static, props: {page: 'lorem', title: 'Track my Order'} },
  // { name: 'customer-service', path: '/customer-service', component: Static, props: {page: 'lorem', title: 'Customer service'} },
  // { name: 'store-locator', path: '/store-locator', component: Static, props: {page: 'lorem', title: 'Store locator'} },
  // { name: 'size-guide', path: '/size-guide', component: Static, props: {page: 'lorem', title: 'Size guide'} },
  // { name: 'gift-card', path: '/gift-card', component: Static, props: {page: 'lorem', title: 'Gift card'} },
  // { name: 'delivery', path: '/delivery', component: Static, props: {page: 'lorem', title: 'Delivery'} },
  // { name: 'returns', path: '/returns', component: Static, props: {page: 'lorem', title: 'Returns policy'} },
  // { name: 'order-from-catalog', path: '/order-from-catalog', component: Static, props: {page: 'lorem', title: 'Order from catalog'} },
  // { name: 'contact', path: '/contact', component: Static, props: {page: 'contact', title: 'Contact'} },
  // { name: 'compare', path: '/compare', component: Compare, props: {title: 'Compare Products'} },
  // System routes
  { name: '404', path: '*', component: PageNotFound }
]

export default routes
