import rootStore from '@vue-storefront/core/store'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import builder from 'bodybuilder'

export function getLink (link) {
  let origin = window.location.host
  return link.split(origin)[1]
}
export function isSameDomain (link) {
  let reg = new RegExp(window.location.hostname, 'i')
  return link.search(reg) !== -1
}
export function regexValid (value, regex) {
  if (typeof value !== 'string') {
    return false
  }
  return regex.test(value)
}
export function formattedDate (date) {
  return dayjs(date).locale('ru').format('DD MMMM YYYY')
}
export function formattedDateWithoutYear (date) {
  return dayjs(date).locale('ru').format('D MMMM')
}
export function cloneObject (object) {
  return typeof object === 'object' && object
    ? JSON.parse(JSON.stringify(object))
    : object
}
export function isHasKeysObj (object) {
  return object && Object.keys(object).length > 0
}

function isHasKeyObj (object, key) {
  if (isHasKeysObj(object)) {
    for (let k in object) {
      if (k === key) return true
    }
    return false
  } else {
    return false
  }
}

/**
 * Метод позволяет узнать существует ли значение по заданному пути в исходном объекте
 * hasValue(this.product, ['values', 'is-ticker_boolean', '<all_channels>', '<all_locales>'])
 * @param {Object} object исходный объект
 * @param {Array} path массив строк(путь до цели)
 * @returns {Boolean} возвращает булево значение
 */
export function hasValue (object, path = []) {
  if (isHasKeysObj(object) && Array.isArray(path)) {
    for (let key of path) {
      if (isHasKeyObj(object, key)) {
        object = object[key]
      } else {
        return false
      }
    }
    return !!object
  } else {
    return false
  }
}

export function findFieldByCollectionLink (collections, currentCollectionLink, field) {
  if (!field || (field !== 'collection-name' && field !== 'collection-link')) {
    return ''
  }
  for (let name in collections) {
    if (collections[name] === currentCollectionLink && field === 'collection-name') {
      return name
    }
    if (collections[name] === currentCollectionLink && field === 'collection-link') {
      return collections[name]
    }
  }
  return ''
}
// for config product need change visibleProducts = [2, 3, 4]
export function baseFilterProductsQuery ({parentCategory, filters = [], isVip = false, isSpecialEvent = false, route = null, visibleProducts = [1], scope = 'catalog', scriptForES = null, isBrandZone = false}) {
  let searchProductQuery = new SearchQuery()
  const isVipValue = isVip ? 1 : 0
  const categoriesWithSimpleProducts = ['prestige', 'main']

  if (categoriesWithSimpleProducts.indexOf(parentCategory.url_key) !== -1) {
    visibleProducts = [1]
  }

  const eventCode = route && route.params && route.params.eventCode ? route.params.eventCode : ''
  const eventObj = eventCode ? rootStore.state.config.products.events[eventCode] : null

  if (isBrandZone) {
    searchProductQuery = searchProductQuery.applyFilter({key: 'event.keyword', value: {'in': 'brandzone'}})
  }

  if (!isSpecialEvent || (isSpecialEvent && eventObj && eventObj.showVipProducts)) {
    searchProductQuery = searchProductQuery.applyFilter({key: 'is_vip', value: {'eq': isVipValue}})
  }

  if (isSpecialEvent) {
    if (eventObj && eventObj.productsVisibility) {
      visibleProducts = eventObj.productsVisibility
    }

    if (eventObj && eventObj.searchByEventType) {
      searchProductQuery = searchProductQuery.applyFilter({key: 'event.keyword', value: {'in': [eventObj.product_code]}})
    }

    if (eventObj && eventObj.searchByEventCode) {
      searchProductQuery = searchProductQuery.applyFilter({key: 'bq_6009.keyword', value: {'gt': '0'}})
    }

    if (eventObj && eventObj.searchByBrands) {
      const eventBrand = eventObj && eventObj.brands && eventObj.brands.length ? eventObj.brands : []
      searchProductQuery = searchProductQuery.applyFilter({key: 'bq_4750.keyword', value: {'in': eventBrand}}) // bq_4750.keyword - атрибут брэнда
    }
  }
  searchProductQuery = searchProductQuery
    .applyFilter({key: 'visibility', value: {'in': visibleProducts}})
    .applyFilter({key: 'status', value: {'in': [0, 1]}}) /* 2 = disabled, 4 = out of stock */
    .applyFilter({key: 'is_gift_product', value: {'in': [0]}}) /* 0 = without gift product, 1 = only gift product */
  if (rootStore.state.config.products.listOutOfStockProducts === false) {
    searchProductQuery = searchProductQuery.applyFilter({key: 'stock.is_in_stock', value: {'eq': true}})
  }
  // Add available catalog filters
  for (let attrToFilter of filters) {
    searchProductQuery = searchProductQuery.addAvailableFilter({field: attrToFilter, scope, scriptForES})
  }

  let childCats = [parentCategory.id]
  if (parentCategory.children_data) {
    let recurCatFinderBuilder = (category) => {
      if (!category) {
        return
      }

      if (!category.children_data) {
        return
      }

      for (let sc of category.children_data) {
        if (sc && sc.id) {
          childCats.push(sc.id)
        }
        recurCatFinderBuilder(sc)
      }
    }
    recurCatFinderBuilder(parentCategory)
  }
  searchProductQuery = searchProductQuery.applyFilter({key: 'category_ids', value: {'in': childCats}})
  return searchProductQuery
}

export const getBrandPageConfig = (key, value) => {
  const brandPagesConfig = Object.values(rootStore.state.config.brands)
  return brandPagesConfig.find(item => item[key] && item[key] === value)
}

export function fetchQueryByUrlKey (urlKey) {
  let query = builder()
  if (urlKey) {
    query
      .query('bool', b => b
        .orQuery('match_phrase', 'configurable_children.url_key.keyword', { query: urlKey, boost: 2 })
        .orQuery('match_phrase', 'url_key.keyword', urlKey)
      )
    query.getQuery().bool['minimum_should_match'] = 1
  }
  return query.build()
}

export function allSettled (promises) {
  let wrappedPromises = promises.map(p => Promise.resolve(p)
    .then(
      val => ({ status: 'fulfilled', value: val }),
      err => ({ status: 'rejected', reason: err })))
  return Promise.all(wrappedPromises)
}

export function scriptForTranslate (attrToFilter) {
  if (attrToFilter.indexOf('translate') !== -1) {
    return `doc['${attrToFilter}.origin.keyword'].value + ',' + doc['${attrToFilter}.translate.keyword'].value`
  } else {
    return `doc['${attrToFilter}'].value`
  }
}
