import { render, staticRenderFns } from "./ProductBanner.vue?vue&type=template&id=5126e194&scoped=true&"
import script from "./ProductBanner.vue?vue&type=script&lang=js&"
export * from "./ProductBanner.vue?vue&type=script&lang=js&"
import style0 from "./ProductBanner.vue?vue&type=style&index=0&id=5126e194&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5126e194",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/maxxium/storefront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5126e194')) {
      api.createRecord('5126e194', component.options)
    } else {
      api.reload('5126e194', component.options)
    }
    module.hot.accept("./ProductBanner.vue?vue&type=template&id=5126e194&scoped=true&", function () {
      api.rerender('5126e194', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/themes/maxxium-theme/components/theme/blocks/Collection/ProductBanner.vue"
export default component.exports